<template>
  <div />
</template>
<script>
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  mounted() {
    this.$store.dispatch(LOGOUT);
  },
};
</script>
